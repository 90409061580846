import CustomMarker from './CustomGoogleMapMarker';
import { OBSERVER } from '../../plugins';
import { bottomPanel } from './map';
/* global google */

// https://github.com/googlemaps/js-markerclusterer/blob/822ee6b/src/markerclusterer.ts#L29

export function parseUrl() {
  const currentURL = new URL(window.location);
  const urlPath = currentURL.pathname;
  const urlPathParts = urlPath.split('/');
  urlPathParts.shift(); // virer le premier slash
  urlPathParts.shift(); // virer la langue
  urlPathParts.shift(); // virer la region
  urlPathParts.shift(); // virer le nom de la page de carte

  let result = urlPathParts;
  if (result.length === 0 || result[0] === '') {
    result = false;
  }

  return result;
}

export function updateUrl(slugAdress = null, rentID = null) {
  const currentURL = new URL(window.location);
  const urlPath = currentURL.pathname;
  const urlPathParts = urlPath.split('/');

  urlPathParts.shift(); // virer le premier slash

  // construction de la nouvelle URL
  const newUrlPath = [];
  newUrlPath[0] = slugAdress;
  newUrlPath[1] = rentID;

  const mapSection = newUrlPath.filter(Boolean).join('/');

  // creation de la nouvelle URL en utilisant la base de la précédante et en ajoutant le reste
  const newUrl = `/${urlPathParts[0]}/${urlPathParts[1]}/${urlPathParts[2]}/${mapSection}`;

  const stateObj = { foo: 'bar' };
  window.history.pushState(stateObj, 'page 2', newUrl);
}

// ---------------------------------------------------
// Centrer la carte sur un point LatLng
// -----------
export function centerOnPoint(location, map, zoom = false) {
  map.panTo(location);
  if (zoom) { map.setZoom(zoom); }
}

// ---------------------------------------------------
// Initialiser l'auto complete de google sur le champs de recherche
// -----------
export function initSearchForm(map) {
  // -------------------------
  // Initialiser l'auto complete de google sur le champs de recherche
  // -------------------------
  const INPUT_ELEMENT = document.querySelector('.js-search-form-input');
  const AUTOCOMPLETE = new google.maps.places.Autocomplete(INPUT_ELEMENT);

  AUTOCOMPLETE.bindTo('bounds', map);
  AUTOCOMPLETE.setComponentRestrictions({
    country: ['ca'], // Limiter les résultats au Canada
  });
  AUTOCOMPLETE.setTypes(['geocode']);

  AUTOCOMPLETE.addListener('place_changed', () => {
    const PLACE = AUTOCOMPLETE.getPlace();

    if (!PLACE.geometry || !PLACE.geometry.location) {
      return false;
    }

    centerOnPoint(PLACE.geometry.location, map);
    return true;
  });

  // -------------------------
  // Gérer la soumission du form
  // -------------------------
  function onMapSearchSubmit(e) {
    const GEOCODER = new google.maps.Geocoder();
    const ADDRESS_TEXT = e.srcElement.querySelector('.js-search-form-input').value;
    GEOCODER.geocode({ address: ADDRESS_TEXT }, (results, status) => {
      if (status === 'OK') {
        centerOnPoint(results[0].geometry.location, map);
      } else {
        // Rien de trouvé - À gérer plus tard
        alert('Aucuns résultats trouvés');
      }
    });
  }

  OBSERVER.add({
    name: 'mapSearch',
    events: 'submit',
    targets: '.js-map-search-form',
    function: onMapSearchSubmit,
  });
  OBSERVER.on('mapSearch');
}

// ---------------------------------------------------
// Zoomer ou dézoomer la carte
// -----------
export function initZoomButtons(map) {
  function onMapZoom(e) {
    const CURRENT_ZOOM = map.getZoom();
    if (e.target.dataset.zoom === 'in') {
      map.setZoom(CURRENT_ZOOM + 1);
    } else if (e.target.dataset.zoom === 'out') {
      map.setZoom(CURRENT_ZOOM - 1);
    }
  }
  OBSERVER.add({
    name: 'mapZoom',
    events: 'click',
    targets: '.js-map-zoom',
    function: onMapZoom,
  });
  OBSERVER.on('mapZoom');
}

// ---------------------------------------------------
// Désactiver la vue de la sidebar
// -----------
export function deactivateSidebarView() {
  const SIDEBAR = document.querySelector('.js-map-sidebar');

  if (SIDEBAR) {
    const VIEWS = SIDEBAR.querySelectorAll('.js-map-sidebar-view');
    // Désactiver toutes les vues
    for (let i = 0; i < VIEWS.length; i += 1) {
      VIEWS[i].removeAttribute('data-active');
    }
  }
}

// ---------------------------------------------------
// Changer la vue de la sidebar de la carte
// -----------
export function activateSidebarView(view) {
  const SIDEBAR = document.querySelector('.js-map-sidebar');

  if (SIDEBAR) {
    const VIEW = document.querySelector(`.js-map-sidebar-view[data-view="${view}"]`);
    // Activer la bonne vue
    VIEW.setAttribute('data-active', '');
    // Rescroller la vue précédente en haut
    if (VIEW.previousElementSibling) {
      VIEW.previousElementSibling.querySelector('.c-map-sidebar__content').scrollTop = 0;
    }
  }
}

// ---------------------------------------------------
// Initialiser le click sur un loyer
// -----------
export function initHousingClick(map) {
  const BUTTON = document.querySelector('[data-view="list"] .js-map-housing');

  function onHousingClick(e) {
    deactivateSidebarView();

    // Centrer la carte sur le point
    const target = new google.maps.LatLng(e.currentTarget.dataset.lat, e.currentTarget.dataset.lng);
    centerOnPoint(target, map);

    const ADDRESS_SLUG = e.currentTarget.dataset.slug;

    const xhr = new XMLHttpRequest();
    const url = `/api/v1/address/${ADDRESS_SLUG}`;

    xhr.open('GET', url, true);
    xhr.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        // Charger la liste des loyers
        const HOUSINGS = JSON.parse(this.responseText).data.housings;
        const LIST = document.querySelector('[data-view="address"] .js-housings-list');

        // Vider la liste dans la sidebar
        LIST.innerHTML = '';

        // Faire afficher les loyers dans la sidebar
        addHousingsSidebar(HOUSINGS, LIST);

        initAddressClick();
        activateSidebarView('address');
        updateUrl(ADDRESS_SLUG);
      }
    };
    // Sending our request
    xhr.send();
  }
  if (BUTTON) {
    OBSERVER.add({
      name: 'housingClick',
      events: 'click',
      targets: '[data-view="list"] .js-map-housing, .js-map-housing-pin',
      function: onHousingClick,
    });
    OBSERVER.on('housingClick');
  }
}

export function initClusterClick(map) {
  function onClusterClick(e) {
    // Centrer la carte sur le point
    const target = new google.maps.LatLng(e.currentTarget.dataset.lat, e.currentTarget.dataset.lng);
    centerOnPoint(target, map);
  }
  OBSERVER.add({
    name: 'clusterClick',
    events: 'click',
    targets: '.js-map-cluster-pin',
    function: onClusterClick,
  });
  OBSERVER.on('clusterClick');
}

export function initAddressClick() {
  const BUTTON = document.querySelector('[data-view="address"] .js-map-housing');

  function onAddressClick(e) {
    bottomPanel.setSheetHeight(100);

    const CURRENT_ID = e.currentTarget.dataset.id;
    const SLUG = e.currentTarget.dataset.slug;

    $.request('housingMap::onGetHousingDetail', {
      data: { id: CURRENT_ID },
      complete(data) {
        data.then(() => {
          initBackToAddress();
          activateSidebarView('detail');
          updateUrl(SLUG, CURRENT_ID);
        });
      },
    });
  }
  if (BUTTON) {
    OBSERVER.add({
      name: 'addressClick',
      events: 'click',
      targets: '[data-view="address"] .js-map-housing',
      function: onAddressClick,
    });
    OBSERVER.on('addressClick');
    bottomPanel.setSheetHeight(100);
  }
}

// ---------------------------------------------------
// Initialiser le bouton retour à la liste
// -----------
export function initBackToList() {
  const BUTTON = document.querySelector('.js-map-back-to-list');

  function onBackToList() {
    deactivateSidebarView();
    activateSidebarView('list');

    // Vider l'url
    updateUrl();
  }
  if (BUTTON) {
    OBSERVER.add({
      name: 'backToList',
      events: 'click',
      targets: '.js-map-back-to-list',
      function: onBackToList,
    });
    OBSERVER.on('backToList');
  }
}

// ---------------------------------------------------
// Initialiser le bouton retour à la liste d'une adresse
// -----------
export function initBackToAddress() {
  const BUTTON = document.querySelector('.js-map-back-to-address');

  function onBackToAddress() {
    deactivateSidebarView();
    activateSidebarView('address');

    // Retirer la dernière portion de l'url
    let url = parseUrl();
    url = url.slice(0, -1);
    updateUrl(url[0]);
  }
  if (BUTTON) {
    OBSERVER.add({
      name: 'backToAddress',
      events: 'click',
      targets: '.js-map-back-to-address',
      function: onBackToAddress,
    });
    OBSERVER.on('backToAddress');
  }
}

// ---------------------------------------------------
// Ajouter une liste de marqueurs sur la carte
// -----------
export function addMarkers(markers, map, clusterer) {
  const MARKERS_RETURN = [];
  const CURRENT_ADDRESSES = [];

  clusterer.clearMarkers();

  for (let i = 0; i < markers.length; i += 1) {
    if (!CURRENT_ADDRESSES.includes(markers[i].address_slug)) { // Si l'addresse est déjà présente, ne pas la réajouter
      // Créer les puces
      const testMarker1 = new CustomMarker(
        new google.maps.LatLng(markers[i].latitude, markers[i].longitude),
        map,
        {
          id: `${markers[i].id}`,
          lat: `${markers[i].latitude}`,
          lng: `${markers[i].longitude}`,
          slug: `${markers[i].address_slug}`,
        },
      );

      MARKERS_RETURN.push(testMarker1);
      CURRENT_ADDRESSES.push(markers[i].address_slug);
    }
  }

  clusterer.addMarkers(MARKERS_RETURN);

  return MARKERS_RETURN;
}

// ---------------------------------------------------
// Ajouter une liste de marqueurs sur la carte
// -----------
export function addHousingsSidebar(housings, list) {
  const TEMPLATE = document.querySelector('.js-housing-template');

  for (let i = 0; i < housings.length; i += 1) {
    // Ajouter les loyers dans la sidebar
    const CLONE = TEMPLATE.content.cloneNode(true);
    CLONE.querySelector('[data-id]').dataset.id = housings[i].id;
    CLONE.querySelector('[data-id]').dataset.lat = housings[i].latitude;
    CLONE.querySelector('[data-id]').dataset.lng = housings[i].longitude;
    CLONE.querySelector('[data-id]').dataset.slug = `${housings[i].address_slug}`;
    if (housings[i].apartment_number) { // Si il y a un numéro d'appartement, l'ajouter avant l'adresse
      CLONE.querySelector('.js-housing-appartment').innerHTML = `${housings[i].apartment_number}-`;
    }
    CLONE.querySelector('.js-housing-address').innerHTML = `${housings[i].street_number} ${housings[i].street_name}, ${housings[i].city}`;
    CLONE.querySelector('.js-housing-bedrooms').innerHTML = housings[i].number_of_closed_room;
    CLONE.querySelector('.js-housing-year').innerHTML = housings[i].year;
    CLONE.querySelector('.js-housing-cost').innerHTML = housings[i].price;
    if (!housings[i].isNonProfit) { // Retirer le tag OBNL si nécéssaire (Pas dans les données qu'on recoit - À supprimer éventuellement)
      CLONE.querySelector('.js-housing-isNonProfit').remove();
    }
    list.appendChild(CLONE);
  }
}
