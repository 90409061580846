/* eslint-disable no-param-reassign */
import anime from 'animejs';
import { calendarInput, tailSelect } from '../functions/form';
import { scrollToBlock } from '../functions/scrollToBlock';
import { OBSERVER } from '../plugins';
import { toggleFieldContainer } from '../functions/functions';
import { formatYear } from '../functions/masks';

export default class FieldGroup {
  static async init() {
    const TEMPLATES = document.querySelectorAll('[data-field-group-template]');

    TEMPLATES.forEach((template) => {
      const NAME = template.getAttribute('data-field-group-template');

      FieldGroup.addTemplate(template, NAME, false);

      OBSERVER.add({
        name: 'FieldGroup',
        events: 'click',
        targets: `[data-field-group-add="${NAME}"]`,
        function: () => { FieldGroup.addTemplate(template, NAME, true); },
      });
      OBSERVER.on('FieldGroup');
    });
  }

  static addTemplate(template, name, animate) {
    const CLONE = template.content.cloneNode(true);
    const FIRST_CLONE_ELEMENT = CLONE.querySelector(`[data-field-group="${name}"]`);

    if (animate) {
      FIRST_CLONE_ELEMENT.classList.add('is-hidden');
    }

    const CONTAINER = document.querySelector(`[data-field-group-container="${name}"]`);
    const ELEMENTS = CONTAINER.querySelectorAll(`[data-field-group="${name}"]`);
    const index = ELEMENTS.length;

    FieldGroup.setTitle(CLONE, index);
    FieldGroup.setID(CLONE, index, name);
    FieldGroup.setFor(CLONE, index, name);
    FieldGroup.setName(CLONE, index, name);
    FieldGroup.setButtonData(CLONE, index, name);
    FieldGroup.setHiddenContainerData(CLONE, index, name);

    document.querySelector('[data-field-group-container]').appendChild(CLONE);

    const INSTANCES = document.querySelectorAll(`[data-field-group-delete="${name}"]`);
    const INSTANCES_LENGTH = INSTANCES.length;
    if (INSTANCES_LENGTH > 1) {
      INSTANCES.forEach((instance) => {
        instance.classList.remove('is-hidden');
      });
    }

    tailSelect();
    calendarInput();
    toggleFieldContainer();
    formatYear();

    if (animate) {
      const HEIGHT = FIRST_CLONE_ELEMENT.children[0].offsetHeight;
      anime({
        targets: FIRST_CLONE_ELEMENT,
        height: HEIGHT,
        easing: 'easeInOutCubic',
        duration: 500,
        complete: () => {
          FIRST_CLONE_ELEMENT.style.height = '';
        },
      });
      FIRST_CLONE_ELEMENT.classList.remove('is-hidden');
    }

    FieldGroup.addEventOnDeleteButton(name);
  }

  static addEventOnDeleteButton(name) {
    OBSERVER.off('FieldGroupRemove');
    OBSERVER.add({
      name: 'FieldGroupRemove',
      events: 'click',
      targets: `[data-field-group-delete="${name}"]`,
      function: (e) => { FieldGroup.delete(e, name); },
    });
    OBSERVER.on('FieldGroupRemove');
  }

  static delete(e, name) {
    const ELEMENT = e.currentTarget.closest(`[data-field-group="${name}"]`);
    ELEMENT.style.height = ELEMENT.children[0].offsetHeight;
    const INSTANCES = document.querySelectorAll(`[data-field-group-delete="${name}"]`);
    const INSTANCES_LENGTH = INSTANCES.length;
    if (INSTANCES_LENGTH - 1 <= 1) {
      INSTANCES.forEach((instance) => {
        instance.classList.add('is-hidden');
      });
    }
    anime({
      targets: ELEMENT,
      height: '0px',
      easing: 'easeInOutCubic',
      duration: 500,
      complete: () => {
        ELEMENT.remove();
        FieldGroup.updateIndex(name);
      },
    });
    ELEMENT.classList.add('is-hidden');
    if (ELEMENT.previousElementSibling) {
      scrollToBlock({
        scrollTo: ELEMENT.previousElementSibling,
        easing: 'easeInOutCubic',
        duration: 500,
        offset: ELEMENT.previousElementSibling.offsetHeight - window.innerHeight,
      });
    }
  }

  static updateIndex(name) {
    const ELEMENTS = document.querySelectorAll(`[data-field-group="${name}"]`);
    ELEMENTS.forEach((element, index) => {
      FieldGroup.setTitle(element, index);
      FieldGroup.setID(element, index, name);
      FieldGroup.setFor(element, index, name);
      FieldGroup.setName(element, index, name);
    });
  }

  // Setup ou update le titre pour qu'il ressemble à: logement #1
  static setTitle(fieldGroup, index) {
    const SEPARATOR = '#';
    const SELECTOR = '[data-field-group-title]';
    const ELEMENT = fieldGroup.querySelector(SELECTOR);
    if (typeof ELEMENT !== 'undefined' && ELEMENT !== null) {
      const CONTENT_WITHOUT_SEPARATOR = ELEMENT.textContent.split(SEPARATOR)[0];
      const CONTENT = `${CONTENT_WITHOUT_SEPARATOR}${SEPARATOR}${index + 1}`;
      ELEMENT.textContent = CONTENT;
    }
  }

  // Setup ou update le ID pour qu'il ressemble à: form-step-1-first-name--adult-1
  static setID(fieldGroup, index, name) {
    const SEPARATOR = `${name}-`;
    const SELECTOR = `[id*="${SEPARATOR}"]`;
    const ELEMENTS = fieldGroup.querySelectorAll(SELECTOR);
    ELEMENTS.forEach((elementWithID) => {
      const REGEX_RULE = `${name}-([0-9]{1,}|)--`;
      const ID = elementWithID.id.replace(new RegExp(REGEX_RULE, 'g'), `${name}-${index + 1}--`);
      elementWithID.setAttribute('id', ID);
    });
  }

  // Setup ou update le for pour qu'il ressemble à: form-step-1-first-name--adult-1
  static setFor(fieldGroup, index, name) {
    const SEPARATOR = `${name}-`;
    const SELECTOR = `[for*="${SEPARATOR}"]`;
    const ELEMENTS = fieldGroup.querySelectorAll(SELECTOR);
    ELEMENTS.forEach((elementWithFor) => {
      const REGEX_RULE = `${name}-([0-9]{1,}|)--`;
      const FOR = elementWithFor.getAttribute('for').replace(new RegExp(REGEX_RULE, 'g'), `${name}-${index + 1}--`);
      elementWithFor.setAttribute('for', FOR);
    });
  }

  // Setup ou update le name pour qu'il ressemble à: form-step-1-first-name--adult-[1]
  static setName(fieldGroup, index, name) {
    const SEPARATOR = `--${name}-`;
    const SELECTOR = `[name*="${SEPARATOR}"]`;
    const ELEMENTS = fieldGroup.querySelectorAll(SELECTOR);
    ELEMENTS.forEach((elementWithName) => {
      const NAME = elementWithName.getAttribute('name').replace(/-\[[^\]]*\]/g, `-[${index + 1}]`);
      elementWithName.setAttribute('name', NAME);
    });
  }

  // Setup ou update le data pour qu'il ressemble à: form-step-1-first-name--adult-[1]
  static setButtonData(fieldGroup, index, name) {
    const SEPARATOR = `--${name}-`;
    const SELECTOR = `[data-field-container-controller*="${SEPARATOR}"]`;
    const ELEMENTS = fieldGroup.querySelectorAll(SELECTOR);
    ELEMENTS.forEach((elementWithData) => {
      const REGEX_RULE = `--${name}-([0-9]{1,}|)--`;
      const DATA = elementWithData.getAttribute('data-field-container-controller').replace(new RegExp(REGEX_RULE, 'g'), `--${name}-${index + 1}--`);
      elementWithData.setAttribute('data-field-container-controller', DATA);
    });
  }

  // Setup ou update le data pour qu'il ressemble à: form-step-1-first-name--adult-[1]
  static setHiddenContainerData(fieldGroup, index, name) {
    const SEPARATOR = `--${name}-`;
    const SELECTOR = `[data-field-container*="${SEPARATOR}"]`;
    const ELEMENTS = fieldGroup.querySelectorAll(SELECTOR);
    ELEMENTS.forEach((elementWithData) => {
      const REGEX_RULE = `--${name}-([0-9]{1,}|)--`;
      const DATA = elementWithData.getAttribute('data-field-container').replace(new RegExp(REGEX_RULE, 'g'), `--${name}-${index + 1}--`);
      elementWithData.setAttribute('data-field-container', DATA);
    });
  }
}
