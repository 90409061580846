import { centerOnPoint } from './mapUtils'; // Utilitaires de la carte

/* global google */

export default function CustomMarker(latlng, map, args) {
  this.latlng = latlng;
  this.args = args;
  this.setMap(map);
  this.map = map;
}

CustomMarker.prototype = new google.maps.OverlayView();

CustomMarker.prototype.draw = function() {
  // const self = this;
  let div = this.div;
  const point = this.getProjection().fromLatLngToDivPixel(this.latlng);

  if (!div) {
    div = this.div = document.createElement('div');

    div.className = 'c-map-pin';

    if (typeof (this.args.id) !== 'undefined') {
      div.dataset.id = this.args.id;
    }
    if (typeof (this.args.lat) !== 'undefined') {
      div.dataset.lat = this.args.lat;
    }
    if (typeof (this.args.lng) !== 'undefined') {
      div.dataset.lng = this.args.lng;
    }
    if (typeof (this.args.slug) !== 'undefined') {
      div.dataset.slug = this.args.slug;
    }

    // Puce indiquant la position de l'utilisateur
    if (this.args.modifier === 'location') {
      div.className = `${div.className} ${div.className}--location`;
      div.innerHTML = '<div class="c-map-pin__outer"></div>';
    // Puce d'agrégation
    } else if (this.args.modifier === 'cluster') {
      div.className = `${div.className} ${div.className}--cluster`;
      div.innerHTML = `<div class="c-map-pin__cluster">${this.args.cluster}</div>`;
    // Puce normale
    } else {
      div.className = `${div.className} js-map-housing-pin`;
    }

    google.maps.event.addDomListener(div, 'click', () => {
      // Gérer le clic sur un cluster
      if (typeof (this.args.bounds) !== 'undefined') {
        this.map.fitBounds(this.args.bounds);
      }
      // Gérer le clic sur la puce de localisation
      if (this.args.modifier === 'location') {
        centerOnPoint(this.latlng, this.map);
      }
    });

    const panes = this.getPanes();
    panes.overlayImage.appendChild(div);
  }

  if (point) {
    div.style.left = `${point.x}px`;
    div.style.top = `${point.y}px`;
  }
};

CustomMarker.prototype.remove = function () {
  if (this.div) {
    this.div.parentNode.removeChild(this.div);
    this.div = null;
  }
};

CustomMarker.prototype.setVisible = function (visible) {
  if (visible) {
    this.div.style.display = 'block';
  } else {
    this.div.style.display = 'none';
  }
};

CustomMarker.prototype.getVisible = function () {
  return true;
};

CustomMarker.prototype.setPosition = function (position) {
  this.set('position', position);
};

CustomMarker.prototype.getPosition = function () {
  return this.latlng;
};

CustomMarker.prototype.setMap = function (map) {
  this.set('map', map);
};

CustomMarker.prototype.getBounds = function () {
  return new google.maps.LatLngBounds(this.latlng, this.latlng);
};

CustomMarker.prototype.getPoint = function () {
  const bounds = this.getBounds();
  const projection = this.getProjection();
  const sw = projection.fromLatLngToDivPixel(bounds.getSouthWest());
  const ne = projection.fromLatLngToDivPixel(bounds.getNorthEast());

  return new google.maps.Point(sw.x, ne.y);
};
