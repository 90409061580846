import $ from 'jquery';
import ShareButtons from 'share-buttons/dist/share-buttons';
import { OBSERVER } from '../plugins';
import { createCookie, getCookie } from './helper';
import Overlay from '../vendor/overlay';

export function overlayMenu() {
  const overlayName = 'menu';

  if (document.querySelector(`[data-overlay="${overlayName}"]`)) {
    const overlay = new Overlay({
      name: overlayName,
      click: {
        buttons: {
          toggle: `[data-overlay-toggle="${overlayName}"]`,
          close: `[data-overlay-close="${overlayName}"]`,
        },
      },
      options: {
        speed: 1000,
        goToSelector: 'html, body',
      },
    });

    overlay.init();
  }
}

export function overlayAsideContent() {
  const overlayName = 'aside-content';

  if (document.querySelector(`[data-overlay="${overlayName}"]`)) {
    const overlay = new Overlay({
      name: overlayName,
      click: {
        buttons: {
          open: `[data-overlay-open="${overlayName}"]`,
          close: `[data-overlay-close="${overlayName}"]`,
        },
      },
      options: {
        speed: 800,
      },
    });

    overlay.init();
  }
}

export function overlayEmail() {
  const overlayName = 'email';

  if (document.querySelector(`[data-overlay="${overlayName}"]`)) {
    const overlay = new Overlay({
      name: overlayName,
      click: {
        buttons: {
          open: `[data-overlay-open="${overlayName}"]`,
          close: `[data-overlay-close="${overlayName}"]`,
        },
      },
      options: {
        speed: 800,
      },
    });

    overlay.init();
  }
}

export function overlayShare() {
  const ovShare = new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '.js-open-overlay-share',
        close: '.js-close-overlay-share',
      },
    },
    options: {
      speed: 800,
    },
  });

  ovShare.init();

  window.ovShare = ovShare;

  // if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
  //   __sharethis__.init(__sharethis__.config);
  // }

  // Message de confirmation de copie de l'URL ----------------
  /* L'alert bloque le copy, donc il se fait juste quand on clique sur "OK",
    donc si on veux un alert, il faut mettre un ti timeout de 300ms
    ou ben faire notre prope ti popup sans bouton qui disparait après quelques secondes. */
  function confirmClipboardCopy() {
    setTimeout(() => {
      alert('L’adresse URL a bien été copiée dans votre presse-papier.');
    }, 300);
  }
  OBSERVER.add({
    name: 'confirmClipboardCopy',
    events: 'click',
    targets: '.js-share-copy',
    function: confirmClipboardCopy,
  });
  OBSERVER.on('confirmClipboardCopy');

  // Gestion de l'ouverture de l'overlay ----------------------
  const openShareOverlay = (e) => {
    if (e.currentTarget.dataset.rubric) {
      window.history.pushState(null, null, `#${e.currentTarget.dataset.rubric}`);
    }
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
    document.querySelector('.overlay-share-buttons').setAttribute('data-url', window.location.href);
    if ($('html')[0].lang === 'fr') {
      // eslint-disable-next-line max-len
      document.querySelector('.c-overlay-share__email').setAttribute('href', `mailto:?Subject=Registre des loyers - À consulter&body=Je vous recommande de consulter les informations se trouvant sur cette page ${window.location.href}`);
    } else {
      // eslint-disable-next-line max-len
      document.querySelector('.c-overlay-share__email').setAttribute('href', `mailto:?Subject=Rental Registry - To consult&body=You might be interested in the information found on this page of the Rental Registry ${window.location.href}`);
    }
    ShareButtons.update();
  };

  OBSERVER.add({
    name: 'openShareOverlay',
    events: 'click',
    targets: '.js-open-overlay-share',
    function: openShareOverlay,
  });
  OBSERVER.on('openShareOverlay');

  // Gestion de la fermeture de l'overlay ---------------------
  const closeShareOverlay = () => {
    window.history.replaceState(null, null, ' ');
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href);
    document.querySelector('.overlay-share-buttons').setAttribute('data-url', window.location.href);
    if ($('html')[0].lang === 'fr') {
      // eslint-disable-next-line max-len
      document.querySelector('.c-overlay-share__email').setAttribute('href', `mailto:?Subject=Registre des loyers - À consulter&body=Je vous recommande de consulter les informations se trouvant sur cette page ${window.location.href}`);
    } else {
      // eslint-disable-next-line max-len
      document.querySelector('.c-overlay-share__email').setAttribute('href', `mailto:?Subject=Rental Registry - To consult&body=You might be interested in the information found on this page of the Rental Registry ${window.location.href}`);
    }
    ShareButtons.update();
  };

  OBSERVER.add({
    name: 'closeShareOverlay',
    events: 'click',
    targets: '.js-close-overlay-share',
    function: closeShareOverlay,
  });
  OBSERVER.on('closeShareOverlay');
}

// Fonction gérant l'overlay cookies
export function overlayCookies() {
  if (getCookie('acceptCookies') !== 'true') {
    const ovCookies = new Overlay({
      name: 'cookies',
      click: {
        buttons: {
          close: '.js-close-overlay-cookies',
        },
      },
      timeout: {
        delay: 1000,
      },
      options: {
        speed: 800,
        closeOnResize: false,
        disabledPageScroll: false,
      },
    });

    ovCookies.init();

    // TODO: Mettre le cookie dans les cookies de October
    const acceptCookies = () => {
      createCookie('acceptCookies', 'true', 365);
    };

    OBSERVER.add({
      name: 'overlayCookies',
      events: 'click',
      targets: '.js-overlay-cookies-button-accept',
      function: acceptCookies,
    });
    OBSERVER.on('overlayCookies');
  }
}

function isScrollable(element) {
  return element.scrollWidth > element.clientWidth || element.scrollHeight > element.clientHeight;
}

export function overlayConsentement() {
  // Overlay confidentialité
  if (document.querySelector('[data-overlay="confidentialite"]')) {
    const overlayConfidentialite = new Overlay({
      name: 'confidentialite',
      click: {
        buttons: {
          open: '[data-overlay-open="confidentialite"]',
          close: '[data-overlay-close="confidentialite"]',
        },
      },
      options: {
        speed: 800,
        closeOnResize: false,
        disabledPageScroll: true,
      },
    });

    overlayConfidentialite.init();

    const checkboxForm2 = document.querySelector('#form-checkbox-2');
    const checkboxLabel2 = document.querySelector('#form-checkbox-2-label');

    const checkboxConfidentialiteOpenOverlay = () => {
      if (checkboxForm2.checked) {
        checkboxLabel2.classList.remove('--checked');
        checkboxForm2.checked = false;
        overlayConfidentialite.open();
      }
    };

    OBSERVER.add({
      name: 'checkboxConfidentialiteOpenOverlay',
      events: 'click',
      targets: '#form-checkbox-2',
      function: checkboxConfidentialiteOpenOverlay,
    });
    OBSERVER.on('checkboxConfidentialiteOpenOverlay');

    const checkboxConfidentialiteAccept = () => {
      checkboxLabel2.classList.add('--checked');
      checkboxForm2.checked = true;
    };

    OBSERVER.add({
      name: 'checkboxConfidentialiteAccept',
      events: 'click',
      targets: '.button-accept-confidentialite',
      function: checkboxConfidentialiteAccept,
    });
    OBSERVER.on('checkboxConfidentialiteAccept');

    if (isScrollable(document.querySelector('.confidentialite'))) {
      document.querySelector('.confidentialite').addEventListener('scroll', () => {
        const button = document.querySelector('.button-accept-confidentialite');
        const container = document.querySelector('.confidentialite');
        const disable = container.scrollTop <= (container.scrollHeight - container.offsetHeight);

        if (disable) {
          button.classList.add('disabled');
          button.setAttribute('disabled', '');
        } else {
          button.classList.remove('disabled');
          button.removeAttribute('disabled');
        }
      });
    } else {
      const button = document.querySelector('.button-accept-confidentialite');
      button.classList.remove('disabled');
      button.removeAttribute('disabled');
    }
  }

  // Overlay conditions d'utilisation
  if (document.querySelector('[data-overlay="conditions"]')) {
    const overlayConditions = new Overlay({
      name: 'conditions',
      click: {
        buttons: {
          open: '[data-overlay-open="conditions"]',
          close: '[data-overlay-close="conditions"]',
        },
      },
      options: {
        speed: 800,
        closeOnResize: false,
        disabledPageScroll: true,
      },
    });

    overlayConditions.init();

    const checkboxForm1 = document.querySelector('#form-checkbox-1');
    const checkboxLabel1 = document.querySelector('#form-checkbox-1-label');

    const checkboxConditionsOpenOverlay = () => {
      if (checkboxForm1.checked) {
        checkboxLabel1.classList.remove('--checked');
        checkboxForm1.checked = false;
        overlayConditions.open();
      }
    };

    OBSERVER.add({
      name: 'checkboxConditionsOpenOverlay',
      events: 'click',
      targets: '#form-checkbox-1',
      function: checkboxConditionsOpenOverlay,
    });
    OBSERVER.on('checkboxConditionsOpenOverlay');

    const checkboxConditionsAccept = () => {
      checkboxLabel1.classList.add('--checked');
      checkboxForm1.checked = true;
    };

    OBSERVER.add({
      name: 'checkboxConditionsAccept',
      events: 'click',
      targets: '.button-accept-conditions',
      function: checkboxConditionsAccept,
    });
    OBSERVER.on('checkboxConditionsAccept');

    if (isScrollable(document.querySelector('.conditions'))) {
      document.querySelector('.conditions').addEventListener('scroll', () => {
        const button = document.querySelector('.button-accept-conditions');
        const container = document.querySelector('.conditions');
        const disable = container.scrollTop <= (container.scrollHeight - container.offsetHeight);

        if (disable) {
          button.classList.add('disabled');
          button.setAttribute('disabled', '');
        } else {
          button.classList.remove('disabled');
          button.removeAttribute('disabled');
        }
      });
    } else {
      const button = document.querySelector('.button-accept-conditions');
      button.classList.remove('disabled');
      button.removeAttribute('disabled');
    }
  }
}
