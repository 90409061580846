import $ from 'jquery';
import { OBSERVER } from '../plugins';

function updateSelectFieldOptions() {
  const selectedOptions = document.querySelectorAll('.selected');
  selectedOptions.forEach((option) => {
    option.classList.remove('selected');
  });

  $('option').removeAttr('selected');
}

export default function bumperTranslate() {
  let lang = 'fr';
  let oldLang;
  const selectFr = document.getElementById('form-bumper-region-fr');
  const selectEn = document.getElementById('form-bumper-region-en');

  const translatePage = (e) => {
    if (e) {
      oldLang = lang;
      lang = e.currentTarget.id;
    } else {
      lang = $('html')[0].lang;
    }

    if (lang === 'en' && lang !== oldLang) {
      // Anglais
      $('#js_fr').hide();
      $('#js_en').show();

      if (selectFr && selectFr.value !== 'Sélectionnez une région') {
        selectEn.value = selectFr.value;

        // Dropdown desktop
        if (document.querySelector(`[data-key=${selectEn.value}]`)) {
          updateSelectFieldOptions();
        }

        // Label desktop
        if (document.querySelector('.label-inner')) {
          $('.label-inner').html(selectEn.options[selectEn.selectedIndex].text);
        }

        // Mobile
        $(`#${selectFr.value}-en`).html(selectEn.options[selectEn.selectedIndex].text);
      }

      if (document.querySelector('#form-bumper-region-en-error') && document.querySelector('#form-bumper-region-en-error').innerHTML) {
        document.querySelector('#form-bumper-region-en-error').innerHTML = 'This field is required.';
      }
    } else if (lang !== oldLang) {
      // Français
      $('#js_en').hide();
      $('#js_fr').show();

      if (selectEn && selectEn.value !== 'Select a region') {
        selectFr.value = selectEn.value;

        // Dropdown desktop
        if (document.querySelector(`[data-key=${selectFr.value}]`)) {
          updateSelectFieldOptions();
        }

        // Label desktop
        if (document.querySelector('.label-inner')) {
          $('.label-inner').html(selectFr.options[selectFr.selectedIndex].text);
        }

        // Mobile
        $(`#${selectEn.value}-fr`).html(selectFr.options[selectFr.selectedIndex].text);
      }

      if (document.querySelector('#form-bumper-region-fr-error') && document.querySelector('#form-bumper-region-fr-error').innerHTML) {
        document.querySelector('#form-bumper-region-fr-error').innerHTML = 'Ce champ est obligatoire.';
      }
    }
  };
  OBSERVER.add({
    name: 'bumperPage',
    events: 'click',
    targets: '#fr, #en',
    function: translatePage,
  });

  OBSERVER.on('bumperPage');

  translatePage();
}
