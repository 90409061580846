import anime from 'animejs/lib/anime.es';
import $ from 'jquery';
import { Datepicker } from 'vanillajs-datepicker';
import { OBSERVER } from '../plugins';
import { getCookie, setCookie } from './helper';
import Accordions from '../classes/accordions';
// import { scrollToBlock } from './scrollToBlock';

// Permet de créer un calendrier
export function calendar() {
  if (!document.querySelector('.js-calendar')) {
    // s'il n'y a pas d'events
    return;
  }

  const eventsDatesList = document.querySelector('.js-calendar').dataset.list;
  const eventsDatesEnabled = eventsDatesList.split(',');
  const datepickerCalendar = document.querySelector('.js-calendar');

  if ($('html')[0].lang === 'fr') {
    Datepicker.locales.fr = {
      days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
      months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
      monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
      today: "Aujourd'hui",
      monthsTitle: 'Mois',
      clear: 'Effacer',
      weekStart: 0,
      format: 'dd/mm/yyyy',
    };
  } else {
    Datepicker.locales.fr = {
      days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      daysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      daysMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      today: 'Today',
      monthsTitle: 'Moth',
      clear: 'Clear',
      weekStart: 0,
      format: 'mm/dd/yyyy',
    };
  }

  // eslint-disable-next-line no-unused-vars
  const datepicker = new Datepicker(datepickerCalendar, {
    language: 'fr',
    prevArrow: `<svg><use xlink:href="/themes/${window.config.theme_path}/assets/medias/images/icons/symbols.svg#ico-pointer"></use></svg>`,
    nextArrow: `<svg><use xlink:href="/themes/${window.config.theme_path}/assets/medias/images/icons/symbols.svg#ico-pointer"></use></svg>`,
    maxView: 0,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    beforeShowDay: (date) => {
      // Rendre seulement les dates de la liste d'événements disponibles
      const allDates = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      return eventsDatesEnabled.indexOf(allDates) !== -1;
    },
  });

  const onChangeDate = (e) => {
    const theTimestamp = Date.parse(e.detail.date) / 1000; // Le timestamp du datepicker est en millisecondes, il faut le mettre en secondes
    $.request('onChangeDate', {
      data: { dateFilter: theTimestamp },
      // update: {'evenementlist::eventslist':'#eventsWrapper'},
      // complete: function (data) { data.then(function(data){ eventsLoaded(data) })},
      complete: (data) => {
        data.then((data2) => {
          // eslint-disable-next-line no-undef
          Barba.Pjax.goTo(`/evenements/1/${data2.date}`);
        });
      },
    });

    // Fermer l'overlay quand on clique
    // document.querySelector('#overlayCalendar .wrapper a.close span.x').click();
  };

  OBSERVER.add({
    name: 'calendar',
    events: 'changeDate',
    targets: '.js-calendar',
    function: onChangeDate,
  });
  OBSERVER.on('calendar');
}

// Copier du texte
export function copyTextToClipboard(text) {
  const pos = $(document).scrollTop();

  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error(err);
  }

  document.body.removeChild(textArea);
  $(document).scrollTop(pos);
}

// Ajoute un target blank sur tous les liens externes et PDF
// Empêche une transition si la destination est la page actuelle
export function forceBlankOnExterneAndPdfLinks() {
  let i;
  const anchors = document.querySelectorAll('a[href]');
  for (i = 0; i < anchors.length; i += 1) {
    // eslint-disable-next-line no-script-url
    if (anchors[i].target !== '_blank' && anchors[i].href !== 'javascript:;') {
      if (
        window.location.hostname !== anchors[i].hostname
        || anchors[i].href.match('\\.pdf$')
        || window.location.protocol !== anchors[i].protocol
      ) {
        anchors[i].setAttribute('target', '_blank');
      }
    }
  }
}

// Ajoute les metas pour le contenu og
export function ogContent(data) {
  const ogUrl = data.next.html.match(/<meta.*property="og:url".*content="(.*)".*\/>/i)[1];
  const ogImage = data.next.html.match(/<meta.*property="og:image".*content="(.*)".*\/>/i)[1];
  const ogTitle = data.next.html.match(/<meta.*property="og:title".*content="(.*)".*\/>/i)[1];
  const ogDescription = data.next.html.match(/<meta.*property="og:description".*content="(.*)".*\/>/i)[1];
  document.querySelector('meta[property="og:url"]').setAttribute('content', ogUrl);
  document.querySelector('meta[property="og:image"]').setAttribute('content', ogImage);
  document.querySelector('meta[property="og:title"]').setAttribute('content', ogTitle);
  document.querySelector('meta[property="og:description"]').setAttribute('content', ogDescription);
}

// Permet d'aller au backend
export function backendKeyBind() {
  $('body').on('keydown', (e) => {
    const keycode = e.keyCode ? e.keyCode : e.which;
    if ((e.ctrlKey && keycode === 13) || (e.metaKey && keycode === 13)) {
      window.location = '/administration';
      return false;
    }
    return true;
  });
}

export function aaa() {
  const AAA = document.querySelectorAll('.js-aaa');
  const AAA_LENGTH = AAA.length;
  if (AAA_LENGTH <= 0) { return; }

  const HTML = document.querySelector('html');

  const SET_A = () => {
    const A_VALUE = getCookie('aaa');

    HTML.style.fontSize = `${A_VALUE}px`;

    const T_ACTIVE_A = document.querySelectorAll(`[data-a="${A_VALUE}"]`);
    const T_ACTIVE_A_LENGTH = T_ACTIVE_A.length;
    if (T_ACTIVE_A_LENGTH > 0) {
      let i;
      for (i = 0; i < T_ACTIVE_A_LENGTH; i += 1) {
        const ACTIVE_A = T_ACTIVE_A[i];
        ACTIVE_A.classList.add('is-active');
      }
    }
  };

  const REMOVE_ACTIVE = () => {
    const T_ACTIVE_A = document.querySelectorAll('[data-a].is-active');
    const T_ACTIVE_A_LENGTH = T_ACTIVE_A.length;
    if (T_ACTIVE_A_LENGTH > 0) {
      let i;
      for (i = 0; i < T_ACTIVE_A_LENGTH; i += 1) {
        const ACTIVE_A = T_ACTIVE_A[i];
        ACTIVE_A.classList.remove('is-active');
      }
    }
  };

  const SET_COOKIE = (e) => {
    let NEXT_A = e.currentTarget.querySelector('[data-a].is-active').nextElementSibling;
    if (typeof NEXT_A === 'undefined' || NEXT_A === null) {
      NEXT_A = e.currentTarget.querySelector('[data-a]');
    }
    setCookie('aaa', NEXT_A.getAttribute('data-a'), 7);
    REMOVE_ACTIVE();
    SET_A();
  };

  const COOKIE = getCookie('aaa');
  if (COOKIE === '') {
    setCookie('aaa', '10', 7);
  } else {
    setCookie('aaa', COOKIE, 7);
  }
  SET_A();

  OBSERVER.add({
    name: 'aaa',
    events: 'click',
    targets: '.js-aaa',
    function: SET_COOKIE,
  });
  OBSERVER.on('aaa');
}

export function dropdown() {
  const TOGGLE_DROPDOWN = (e) => {
    e.currentTarget.classList.toggle('is-active');
    const CONTAINER = e.currentTarget.closest('.js-dropdown-container');
    const DROPDOWN = CONTAINER.querySelector('.js-dropdown');
    DROPDOWN.classList.toggle('is-active');
  };

  // const CLOSE_DROPDOWN = () => {
  //   document.querySelectorAll('.js-dropdown, .js-dropdown-button').forEach((e) => {
  //     e.classList.remove('is-active');
  //   });
  // };

  // OBSERVER.add({
  //   name: 'dropdown',
  //   events: 'click',
  //   targets: 'html :not(js-dropdown-container)',
  //   function: CLOSE_DROPDOWN,
  // });
  OBSERVER.add({
    name: 'dropdown',
    events: 'click',
    targets: '.js-dropdown-button',
    function: TOGGLE_DROPDOWN,
  });
  OBSERVER.on('dropdown');
}

export function toggleFieldContainer() {
  const OPEN = (container, height) => {
    const CONTAINER = container;

    anime({
      targets: CONTAINER,
      height,
      easing: 'easeOutCubic',
      duration: 500,
      complete: () => {
        CONTAINER.style.height = '100%';
      },
    });

    CONTAINER.classList.remove('is-hidden');
  };

  const CLOSE = (container, height) => {
    const CONTAINER = container;

    CONTAINER.style.height = `${height}px`;

    anime({
      targets: CONTAINER,
      height: '0px',
      easing: 'easeOutCubic',
      duration: 500,
    });

    CONTAINER.classList.add('is-hidden');
  };

  const TOGGLE_FIELD_CONTAINER_VISIBILITY = (e) => {
    const BUTTON = e.target;
    // eslint-disable-next-line max-len
    const BUTTON_ATTRIBUTE = BUTTON.tagName === 'SELECT' ? BUTTON.querySelector(`[value="${BUTTON.value}"]`).getAttribute('data-field-container-controller') : BUTTON.getAttribute('data-field-container-controller');
    const BUTTON_STATE = BUTTON.tagName === 'SELECT' ? BUTTON.querySelector(`[value="${BUTTON.value}"]`).getAttribute('data-field-container-state') : BUTTON.getAttribute('data-field-container-state');
    const CONTAINERS = document.querySelectorAll(`[data-field-container=${BUTTON_ATTRIBUTE}]`);
    let lastOpenedContainer;

    CONTAINERS.forEach((container) => {
      const CONTAINER = container;
      const HEIGHT = CONTAINER.children[0].offsetHeight;
      const IS_HIDDEN = CONTAINER.classList.contains('is-hidden');

      if (BUTTON_STATE === 'show' && IS_HIDDEN) {
        OPEN(CONTAINER, HEIGHT);
      }

      if (BUTTON_STATE === 'hide' && !IS_HIDDEN) {
        CLOSE(CONTAINER, HEIGHT);
      }

      if (BUTTON_STATE === 'showAndHide' && IS_HIDDEN) {
        if (lastOpenedContainer) {
          CLOSE(lastOpenedContainer, HEIGHT);
          setTimeout(() => {
            OPEN(CONTAINER, HEIGHT);
            lastOpenedContainer = CONTAINER;
          }, 300);
        } else {
          OPEN(CONTAINER, HEIGHT);
          lastOpenedContainer = CONTAINER;
        }
      }

      if (BUTTON_STATE === null && IS_HIDDEN) {
        OPEN(CONTAINER, HEIGHT);
      } else if (BUTTON_STATE === null && !IS_HIDDEN) {
        CLOSE(CONTAINER, HEIGHT);
      }
    });
  };

  const BUTTONS = document.querySelectorAll('[data-field-container-controller]:not(.active)');
  BUTTONS.forEach((button) => {
    const BUTTON = button;
    BUTTON.classList.add('active');
    const NAME = BUTTON.getAttribute('name');
    OBSERVER.add({
      name: 'toggleFieldContainer',
      events: 'change',
      targets: `[name="${NAME}"]`,
      function: TOGGLE_FIELD_CONTAINER_VISIBILITY,
    });
  });

  OBSERVER.on('toggleFieldContainer');
}

export function updateDataRequest(request) {
  $('.o-form').attr('data-request', `housingForm::${request}`);
}

export function copyrightHeightCSSVar() {
  const copyright = document.querySelector('.c-copyright');

  function setHeight() {
    const height = copyright.offsetHeight;
    document.documentElement.style.setProperty('--c-copyright-height', `${height}px`);
  }

  if (copyright) {
    setHeight();

    OBSERVER.add({
      name: 'copyrightHeightCSSVar',
      events: 'resize',
      targets: 'window',
      function: setHeight,
    });
    OBSERVER.on('copyrightHeightCSSVar');
  }
}

export function widget() {
  const widgetElement = document.querySelector('.js-accordion-widget');

  if (widgetElement) {
    const widgetAccordion = new Accordions({
      containerClass: 'js-accordions-widget',
      accordionClass: 'js-accordion-widget',
      toggleButtonClass: 'js-accordion-widget-toggle',
      contentClass: 'js-accordion-widget-content',
      openedClass: 'js-accordion-widget-opened',
      duration: 500,
      scrollToAccordion: false,
    });
    widgetAccordion.init();

    const closeWidget = () => {
      if (widgetElement.classList.contains('js-accordion-widget-opened')) {
        widgetAccordion.close(widgetElement);
      }
    };

    OBSERVER.add({
      name: 'widget',
      events: 'scroll resize',
      targets: 'window',
      function: closeWidget,
    });
    OBSERVER.on('widget');
  }
}
