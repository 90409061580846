import barba from '@barba/core';
import LazyLoad from 'vanilla-lazyload';
import tippy from 'tippy.js';
import ShareButtons from 'share-buttons/dist/share-buttons';
import { SCROLLFIRE, OBSERVER } from './plugins';
import defaultTransition from './transitions/default-transition';
import {
  backendKeyBind, ogContent, aaa, dropdown, toggleFieldContainer,
  updateDataRequest,
  copyrightHeightCSSVar,
  widget,
} from './functions/functions';
import dynamicPackage from './functions/dynamic';
import {
  resetDropzone, formsPackage, clearInput,
} from './functions/form';
import {
  overlayShare, overlayAsideContent, overlayEmail, overlayMenu, overlayCookies, overlayConsentement,
} from './functions/overlays';
import bumperTranslate from './functions/bumperTranslate';
import Overlay from './vendor/overlay';
import { formBumperFr, formBumperEn, formStep1, formSuccess } from './functions/validation';
import masksPackage from './functions/masks';
import { removeHoverOnMobile, hundredVH } from './functions/helper';
import FieldGroup from './classes/fieldGroup';
import rubrics from './functions/rubrics';
import { clickToScrollToBlock } from './functions/scrollToBlock';
import initMap from './functions/map/map';
import swiperFilters from './functions/sliders';

window.formSuccess = formSuccess;
window.resetDropzone = resetDropzone;
window.updateDataRequest = updateDataRequest;
window.ShareButtons = ShareButtons;

const initBarba = () => {
  barba.hooks.beforeEnter(async ({ current }) => {
    if (current.container != null) {
      current.container.remove();
    }
    copyrightHeightCSSVar();
    aaa();
    dropdown();
    overlayShare();
    overlayMenu();
    overlayEmail();
    overlayAsideContent();
    overlayCookies();
    overlayConsentement();
    bumperTranslate();
    swiperFilters();
    backendKeyBind();
    ShareButtons.update();
    SCROLLFIRE.init();
    removeHoverOnMobile();
    clickToScrollToBlock({ selector: '[data-js="scroll-to-top"]' });
    clickToScrollToBlock({ selector: '.js-scrolldown', scrollTo: '.js-scrolldown-destination' });
    formStep1();
    formBumperFr();
    formBumperEn();
    toggleFieldContainer();
    formsPackage();
    masksPackage();
    rubrics();
    dynamicPackage();
    await FieldGroup.init();
    initMap();
    hundredVH();
    clearInput();
    widget();
    // eslint-disable-next-line no-undef
    initFormBuilder();
    tippy('[data-tippy-content]', {
      theme: 'light',
      arrow: false,
    });
    window.lazyload = new LazyLoad();
  });

  barba.hooks.enter((data) => {
    ogContent(data);
  });

  barba.hooks.afterLeave(() => {
    OBSERVER.allOff();
    OBSERVER.removeAll();
    Overlay.destroyAll();
    SCROLLFIRE.destroy();
  });

  barba.init({
    prefetchIgnore: true,
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    transitions: [defaultTransition()],
  });
};

document.addEventListener('DOMContentLoaded', () => {
  initBarba();
});
