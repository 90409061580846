import anime from 'animejs/lib/anime.es';

const defaultTransition = () => {
  const target = document.querySelector('.js-default-transition');
  const targetContainer = document.querySelector('.js-default-transition-container');
  const duration = 800;
  const easing = 'easeInOutQuart';

  return {
    name: 'default-transition',

    // Le rideau apparaît par en bas
    leave: () => new Promise((resolve) => {
      target.style.display = 'block';
      targetContainer.style.opacity = 0;

      anime
        .timeline({
          targets: targetContainer,
          duration,
          easing,
        })
        .add({
          opacity: 1,
        })
        .finished.then(() => {
          resolve();
        });
    }),

    // Le rideau disparaît par en haut
    enter: () => new Promise((resolve) => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      anime
        .timeline({
          targets: targetContainer,
          duration,
          easing,
        })
        .add({
          opacity: 0,
        })
        .finished.then(() => {
          target.style.display = '';
          resolve();
        });
    }),

    // Afficher le rideau
    beforeOnce: () => new Promise((resolve) => {
      target.style.display = 'block';
      targetContainer.style.opacity = 1;
      resolve();
    }),

    // Le rideau disparait en opacité
    once: () => new Promise((resolve) => {
      anime
        .timeline({
          targets: targetContainer,
          duration: 1000,
          easing,
        })
        .add({
          opacity: 0,
        })
        .finished.then(() => {
          targetContainer.style.opacity = '';
          target.style.display = '';
          resolve();
        });
    }),
  };
};

export default defaultTransition;
