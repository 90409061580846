import { OBSERVER } from '../plugins';
import { isMobile } from '../functions/helper';

// ---------------------------------------------------
// Gérer le panneau glissable en mobile
// -----------

function getClientY(event) {
  let value = event.clientY;
  if (typeof event.changedTouches !== 'undefined') {
    value = event.changedTouches[0].clientY;
  }
  return value;
}

export default class BottomSheet {
  init() {
    this.sheetContents = document.querySelector('.js-bottom-panel-drag');
    this.draggableArea = document.querySelector('.js-bottom-panel-drag-tab');
    this.steps = [15, 50, 100];
    this.touchPosition = (event) => {
      const touch = event.touches ? event.touches[0] : event;
      return touch;
    };

    this.startPosition = undefined;

    this.dragPosition = undefined;
    [this.sheetHeight] = this.steps; // initialiser le sheet height avec la première valeur du tableau de steps

    // --------------------------------------------------
    // Événements pour le drag
    // --------------------------
    const startEvent = isMobile() ? 'touchstart' : 'mousedown touchstart';
    const moveEvent = isMobile() ? 'touchmove' : 'mousemove touchmove';
    const endEvent = isMobile() ? 'touchend' : 'mouseup touchend';

    OBSERVER.add({
      name: 'startDrag',
      events: startEvent,
      targets: '.js-bottom-panel-drag-tab',
      function: this.onDragStart,
    });
    OBSERVER.on('startDrag');

    OBSERVER.add({
      name: 'moveDrag',
      events: moveEvent,
      targets: 'window',
      function: this.onDragMove,
    });
    OBSERVER.on('moveDrag');

    OBSERVER.add({
      name: 'endDrag',
      events: endEvent,
      targets: '.js-bottom-panel-drag-tab',
      function: this.onDragEnd,
    });
    OBSERVER.on('endDrag');
  }

  // Faire passer le bottom panel à une hauteur voulue
  setSheetHeight(value) {
    this.sheetHeight = Math.max(0, Math.min(100, value));
    this.sheetContents.style.height = `${this.sheetHeight}vh`;

    if (this.sheetHeight >= 100) {
      this.sheetContents.classList.add('--slide-up');
    } else {
      this.sheetContents.classList.remove('--slide-up');
    }

    if (this.sheetHeight <= this.steps[0]) {
      this.sheetContents.classList.add('--slide-down');
    } else {
      this.sheetContents.classList.remove('--slide-down');
    }
  }

  onDragStart = (event) => {
    this.startPosition = getClientY(event);
    this.dragPosition = this.touchPosition(event).pageY;
    this.sheetContents.classList.add('tab-sliding');
    this.draggableArea.style.cursor = 'grabbing';
    document.body.style.cursor = 'grabbing';
  };

  onDragMove = (event) => {
    if (this.dragPosition === undefined) return;
    const y = this.touchPosition(event).pageY;
    const deltaY = this.dragPosition - y;
    const deltaHeight = (deltaY / window.innerHeight) * 100;

    this.setSheetHeight(this.sheetHeight + deltaHeight);
    this.dragPosition = y;
  };

  onDragEnd = (event) => {
    // Calculer quelle distance a été glissée depuis le début du drag, si à vérifier si c'est un clic ou vraiment un glissement
    const diff = Math.abs(this.startPosition - getClientY(event));

    // Si c'est un glissement
    if (diff >= 5) {
      // Calculer à quel point snapper
      const CLOSEST = this.steps.reduce((prev, curr) => (Math.abs(curr - this.sheetHeight) < Math.abs(prev - this.sheetHeight) ? curr : prev));
      this.setSheetHeight(CLOSEST);
    // Si c'est un clic et que le panneau est à son plus bas
    } else if (this.sheetContents.classList.contains('--slide-down')) {
      this.setSheetHeight(100);
    } else {
      this.setSheetHeight(this.steps[0]);
    }

    this.dragPosition = undefined;
    this.sheetContents.classList.remove('tab-sliding');
    this.draggableArea.style.cursor = '';
    document.body.style.cursor = '';
  };
}
