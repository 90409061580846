import $ from 'jquery';
import { OBSERVER } from '../plugins';

function caps() {
  // prevent duplicate event on keyup
  OBSERVER.off('caps');
  OBSERVER.remove('caps');

  const ON_KEYUP = () => {
    const ELEMENTS = document.querySelectorAll('.js-caps input');

    ELEMENTS.forEach((item) => {
      if (item === document.activeElement) {
        const ELEMENT = item;
        ELEMENT.value = ELEMENT.value.toUpperCase();
      }
    });
  };

  OBSERVER.add({
    name: 'caps',
    events: 'keyup',
    function: ON_KEYUP,
  });
  OBSERVER.on('caps');
}

// Le formatage du numéro de téléphone
function formatPhone() {
  $('.js-mask-phone input').mask('000 000-0000');
}

// Le formatage du code postal
function formatPostalCode() {
  const SELECTOR = '.js-mask-zip-code input';

  $(SELECTOR).mask('Z0Z 0Z0', {
    translation: {
      Z: {
        pattern: /[A-Z, a-z]/,
      },
    },
  });
}

// Le formatage d'une date
function formatDate() {
  $('.js-mask-date input').mask('00/00/0000');
}

// Le formatage d'une date
export function formatYear() {
  $('.js-mask-year input').mask('0000');
}

// Le package à appliquer pour les masques
export default function masksPackage() {
  caps();
  formatPhone();
  formatPostalCode();
  formatDate();
  formatYear();
}
