// ---------------------------------------------------
// Fonctions reliées à la carte
// ---------------------------------------------------

// Importation ---------------------------------------

import { MarkerClusterer } from '@googlemaps/markerclusterer';
import mapStyle from './mapStyle'; // Récupère le style de la carte
import {
  initSearchForm, initZoomButtons, centerOnPoint, initHousingClick, initClusterClick, initAddressClick, initBackToList, initBackToAddress,
  addMarkers, addHousingsSidebar, parseUrl, activateSidebarView
} from './mapUtils'; // Utilitaires de la carte

import { OBSERVER } from '../../plugins';
import CustomMarker from './CustomGoogleMapMarker';
import BottomSheet from '../../classes/bottomSheet';

/* global google */
/* global $ */

export const bottomPanel = new BottomSheet();
let map;
let positionMarker;
let userLocation = false;

let clusterer;

// ---------------------------------------------------
// Centrer la carte sur la position de l'utilisateur
// -----------
function centerOnUser() {
  if (userLocation) {
    centerOnPoint(userLocation, map, 15);
  }
}

function initLocalization() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      userLocation = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

      positionMarker = new CustomMarker(
        userLocation,
        map,
        {
          marker_id: 'Location',
          modifier: 'location',
        },
      );

      // Si il n'y a pas d'adresse dans l'url, centrer sur la localisation du user
      if (!parseUrl()) {
        centerOnUser();
      }
    });
  }
}

// ---------------------------------------------------
// Initialiser le bouton Me Géolocaliser
// -----------
function initLocalizeButton() {
  OBSERVER.add({
    name: 'mapLocalize',
    events: 'click',
    targets: '.js-map-localize',
    function: centerOnUser,
  });
  OBSERVER.on('mapLocalize');
}

export async function refreshHousings(bounds) {
  const NE = bounds.getNorthEast();
  const SW = bounds.getSouthWest();
  const rectangle = `/${NE.lat()},${NE.lng()},${SW.lat()},${SW.lng()}`;

  let url = '/api/v1/housings';

  if (bounds) { // Si on a des bounds, les ajouter à l'URL
    url += rectangle;
  }

  // Charger la liste des loyers
  const RESPONSE = await fetch(url);
  const DATAS = await RESPONSE.json();
  const HOUSINGS = DATAS.data.housings;

  // Réinitialiser la liste des marqueurs
  const TEMPLATE_EMPTY = document.querySelector('.js-housing-template-no-items');
  const LIST = document.querySelector('[data-view="list"] .js-housings-list');
  const SIDEBAR_TITLE = document.querySelector('[data-view="list"] .js-map-sidebar-title');

  // Vider la liste dans la sidebar
  LIST.innerHTML = '';

  // Si il y a des loyers à faire afficher
  if (HOUSINGS.length > 0) {
    SIDEBAR_TITLE.style.display = 'block';

    // Faire afficher les puces
    addMarkers(HOUSINGS, map, clusterer);

    // Faire afficher les loyers dans la sidebar
    addHousingsSidebar(HOUSINGS, LIST);
  } else {
    SIDEBAR_TITLE.style.display = 'none';
    const CLONE = TEMPLATE_EMPTY.content.cloneNode(true);
    LIST.appendChild(CLONE);
  }
}

// ---------------------------------------------------
// Faire afficher la carte
// -----------
export default async function initMap() {
  // Vérifier si il y a une carte à afficher dans la page
  const SELECTOR = document.querySelectorAll('.js-map-container');
  const SELECTOR_LENGTH = SELECTOR.length;
  if (SELECTOR_LENGTH <= 0) { return; }

  // Coordonnées de carte par défaut pour chaque province (Plus grande ville)

  // Alberta (AB): Calgary 51.044686, -114.071883
  // Colombie-Britannique (BC): Vancouver 49.282712, -123.120739
  // IPE (PE): Charlottetown 46.238254, -63.131075
  // Manitoba (MB): Winnipeg 49.895408, -97.138520
  // Nouveau-Brunswick (NB): Moncton 46.087795, -64.778236
  // Nouvelle-Écosse (NS): Halifax 44.647536, -63.572791
  // Nunavut (NU): Iqaluit 63.746665, -68.516970
  // Ontario (ON): Ottawa 45.421126, -75.697599
  // Québec (QC): Montréal 45.518801, -73.625355
  // Saskatchewan (SK): Saskatoon 52.157782, -106.670166
  // Terre-Neuve-et-Labrador (NL): Saint-Jean 47.555547, -52.745276
  // Territoires du Nord-Ouest (NT): Yellowknife 62.453955, -114.371789
  // Yukon (YT): Whitehorse 60.719694, -135.052275

  const apiUrlRegion = `/api/v1/regions/${$('html').attr('region')}`;

  // Charger le point central de la région
  const RESPONSE_REGION = await fetch(apiUrlRegion);
  const DATAS_REGION = await RESPONSE_REGION.json();
  const REGION = DATAS_REGION.data.region;

  const mapOptions = {
    zoom: 15,
    center: { lat: parseFloat(REGION.latitude), lng: parseFloat(REGION.longitude) }, // Centre de la carte par défaut
    styles: mapStyle,
    disableDefaultUI: true, // Désactiver le ui par défaut
    gestureHandling: 'greedy',
    minZoom: 13,
    maxZoom: 20,
    restriction: {
      latLngBounds: {
        north: 63,
        south: 42,
        west: -147,
        east: -48,
      },
    },
  };
  map = new google.maps.Map(document.querySelector('.js-map-container'), mapOptions);

  const renderer = {
    render: ({ count, position, bounds }) => new CustomMarker(
      position,
      map,
      {
        modifier: 'cluster',
        cluster: count,
        bounds,
      },
    ),
  };
  clusterer = new MarkerClusterer({ map, undefined, renderer });

  initSearchForm(map);
  initLocalizeButton();
  initZoomButtons(map);

  bottomPanel.init();

  initLocalization();

  initBackToList();
  initBackToAddress();

  google.maps.event.addListener(map, 'idle', async () => { // Ajouter un événement sur le changement de position de la carte
    const BOUNDS = map.getBounds();
    await refreshHousings(BOUNDS);

    setTimeout(() => { // C'est peut être pas la meilleure façon de régler ça, mais sans le timeout ça fonctionne pas...
      initHousingClick(map);
      initClusterClick(map);
    }, 50);
  });

  if (parseUrl()) {
    const url = parseUrl();

    const apiUrl = `/api/v1/address/${url[0]}`;

    // Charger la liste des loyers de l'adresse
    const RESPONSE = await fetch(apiUrl);
    const DATAS = await RESPONSE.json();
    const HOUSINGS = DATAS.data.housings;

    const LIST = document.querySelector('[data-view="address"] .js-housings-list');
    // Faire afficher les loyers dans la sidebar
    addHousingsSidebar(HOUSINGS, LIST);
    initAddressClick();

    // Si cette adresse retourne des résultats
    if (HOUSINGS.length > 0) {
      // Obtenir les coordonnées GPS de cette adresse et centrer la carte
      const HOUSING_LOCATION = new google.maps.LatLng(HOUSINGS[0].latitude, HOUSINGS[0].longitude);
      centerOnPoint(HOUSING_LOCATION, map, 15);

      if (url.length === 3) {
        // Si on affiche le détail d'un loyer
        const CURRENT_ID = url[2];
        $.request('housingMap::onGetHousingDetail', {
          data: { id: CURRENT_ID },
          complete(data) {
            data.then(() => {
              initBackToAddress();
              activateSidebarView('detail');
            });
          },
        });
      } else {
        // Si on affiche les loyers d'une adresse
        activateSidebarView('address');
      }
    }
  }
}
