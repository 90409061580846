import $ from 'jquery';
import { resetDropzone } from './form';

// Fonction contenant tous les messages
function getMessages() {
  let messages;

  if ($('html')[0].lang === 'en') {
    messages = {
      required: 'This field is required.',
      select: 'Please chose an option.',
      email: 'Please enter a valid email address.',
      phone: 'Please enter a valid phone number.',
      zipCode: 'Please enter a valid ZIP code.',
      date: 'Please enter a valid date.',
      accept: 'The file must be a document pdf.',
      file: 'Please provide a adequate file.',
      autocomplete: 'Please use google autocomplete.',
    };
  } else {
    messages = {
      required: 'Ce champ est obligatoire.',
      select: 'Veuillez sélectionner une option.',
      email: 'Veuillez fournir une adresse électronique valide.',
      phone: 'Veuillez fournir un numéro de téléphone valide.',
      zipCode: 'Veuillez fournir un code postal valide.',
      date: 'Veuillez fournir une date valide.',
      accept: 'Le fichier doit être un document pdf.',
      file: 'Veuillez lier le fichier adéquat pour ce champ.',
      autocomplete: 'Veuillez utilisez la complétion automatique et soumettre à nouveau votre loyer. Si votre adresse ne figure pas dans la liste, veuillez communiquer avec le Registre.',
    };
  }

  return messages;
}

let googleAutoComplete;
function googleFillInAddress() {
  const componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    sublocality_level_1: 'long_name',
    administrative_area_level_1: 'short_name',
    postal_code: 'short_name',
  };

  const componentFormId = {
    street_number: 'civic-number',
    route: 'section',
    locality: 'town',
    sublocality_level_1: 'town',
    administrative_area_level_1: 'province',
    postal_code: 'zip-code',
  };

  const place = googleAutoComplete.getPlace();

  $('.googleAddrElement').val('');
  $('.googleAddrElement').removeAttr('disabled');
  $('.googleAddrElement').parent().parent().removeClass('c-input-field--disabled');

  for (let i = 0; i < place.address_components.length; i += 1) {
    const addressType = place.address_components[i].types[0];
    if (componentForm[addressType]) {
      const val = place.address_components[i][componentForm[addressType]];
      document.getElementById(componentFormId[addressType]).value = val;
      document.getElementById(componentFormId[addressType]).focus();
    }
  }

  document.getElementById('addrLatitude').value = place.geometry.location.lat();
  document.getElementById('addrLongitude').value = place.geometry.location.lng();

  $('#full-address').focus();
}

function geolocate() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function getCurrentPosition(position) {
      const geolocation = { lat: position.coords.latitude, lng: position.coords.longitude };
      this.restrictArea(geolocation, position.coords.accuracy);
      // eslint-disable-next-line no-undef
      const circle = new google.maps.Circle({ center: geolocation, radius: position.coords.accuracy });
      googleAutoComplete.setBounds(circle.getBounds());
    });
  }
}

function addressAutocomplete() {
  const autocompleteField = document.getElementById('full-address');

  if (!autocompleteField) {
    return;
  }

  // eslint-disable-next-line no-undef
  googleAutoComplete = new google.maps.places.Autocomplete(autocompleteField, { types: ['geocode'], componentRestrictions: { country: 'ca' } });
  geolocate();
  googleAutoComplete.addListener('place_changed', googleFillInAddress);
}

// Fonction exécutant la validation de chaque formulaire
export function formValidation(formElement, submit, rules, messages, handler) {
  $.validator.setDefaults({
    ignore: [],
  });
  $(formElement).validate({
    debug: false,
    errorElement: 'div',
    focusInvalid: false,
    invalidHandler: (_, validator) => {
      if (!validator.numberOfInvalids()) {
        return; // eslint-disable-next-line semi
      }

      const nbProjectError = validator.errorList.find((item) => item.element.name === 'nbProject');

      if (nbProjectError === undefined) {
        $('html, body').animate({ scrollTop: $(validator.errorList[0].element).offset().top - 50 }, 500);
      }
    },
    rules,
    messages,
    errorPlacement: (error, element) => {
      const hiddenInputs = document.querySelectorAll('.js-input-hidden');
      switch (element.attr('name')) {
        case 'longitude':
          hiddenInputs.forEach((item) => {
            const input = item;
            input.style.visibility = 'visible';
            input.style.position = 'relative';
            input.style.pointerEvents = 'all';
          });
          $('.js-full-address').parent().parent().parent()
            .addClass('--error');
          error.appendTo($('.js-full-address').parent().parent().find('.js-form-error'));
          break;
        default:
          error.appendTo(element.closest('.js-field').find('.js-form-error'));
          break;
      }
    },
    success: (_, element) => {
      const errorField = $(element).closest('.js-field');
      errorField.removeClass('--error');
    },
    highlight: (element) => {
      // Ajouter la class d'erreur au champs
      const errorField = $(element).closest('.js-field');
      errorField.addClass('--error');
    },
    submitHandler: () => {
      if (typeof handler !== 'undefined') {
        handler();
        return false;
      }
      return true;
    },
  });
  // Mettre à jour la validation d'un select au change
  $('select').on('change', function selectChange() {
    $(this).valid();
  });
  $(submit).on('click', () => {
    // À mettre seulement si l'element est un <a>
    // $(formElement).submit();
  });
}

// Reset de validation
export function formSuccess(form, data) {
  const formElement = form;

  if (data.X_OCTOBER_REDIRECT !== undefined) {
    $('body').removeClass('oc-loading');
    $('.stripe-loading-indicator').addClass('loaded');
    $(form)[0].reset();
    resetDropzone(form);
    if (typeof grecaptcha !== 'undefined') {
      // eslint-disable-next-line no-undef
      grecaptcha.reset();
    }

    // <div class="alert alert-success">x</div>

    let message = 'Le formulaire est bien envoyé !';
    const confirmationContainer = form.querySelector('.js-confirmation-message');

    if (confirmationContainer.innerHTML !== '') {
      message = confirmationContainer.innerHTML;
    } else if (document.getElementsByTagName('html')[0].getAttribute('lang') === 'en') {
      message = 'The form has been sent !';
    }

    // Créer l'élément
    const confirmationNode = document.createElement('div');
    confirmationNode.className = 'alert alert-success';
    confirmationNode.textContent = message;

    // Ajouter la confirmation
    $(confirmationNode).insertBefore(confirmationContainer.parentNode.parentNode);

    // Cacher le formulaire
    formElement.style.display = 'none';

    // $.oc.flashMsg({text: message ,class: 'success'})
  }
}

// La validation des courriels
function validationEmail() {
  // eslint-disable-next-line func-names
  $.validator.addMethod('courriel', function (value, element) {
    // eslint-disable-next-line max-len
    return this.optional(element) || /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(value);
  });
}

// La validation du formulaire d'exemple
export function formExample() {
  validationEmail();

  const m = getMessages();

  const rules = {
    dropdownExample: { required: true },
    firstname: { required: true },
    lastname: { required: true },
    email: { required: true, courriel: true },
    phone: { required: true, minlength: 12 },
    'files[]': { accept: false },
    message: { required: true },
    cv: { required: true },
    radioExampleInline: { required: true },
    checkboxExample: { required: true },
  };
  const messages = {
    dropdownExample: { required: m.required },
    firstname: { required: m.required },
    lastname: { required: m.required },
    email: { required: m.required, courriel: m.email, email: m.email },
    phone: { minlength: m.phone },
    message: { required: m.required },
    cv: { required: m.required },
    radioExampleInline: { required: m.required },
    checkboxExample: { required: m.required },
  };

  formValidation('#form-example', '#form-example-submit', rules, messages);
}

// La validation de l'étape 1
export function formStep1() {
  const FORM = document.querySelector('#form-step-1');

  if (typeof FORM === 'undefined' || FORM === null) { return; }

  validationEmail();

  const m = getMessages();

  const rules = {
    'full-address': { required: true },
    'civic-number': { required: true },
    section: { required: true },
    town: { required: true },
    province: { required: true },
    'zip-code': { required: true, minlength: 7 },
    longitude: { required: true },
    'chamber-count': { required: true },
    'year-of-reference': { required: true },
    'monthly-rent-amount': { required: true },
    email: { required: true, courriel: true },
    'confirm-email': { required: true },
  };
  const messages = {
    'full-address': { required: m.required },
    'civic-number': { required: m.required },
    section: { required: m.required },
    town: { required: m.required },
    province: { required: m.required },
    'zip-code': { required: m.required, minlength: m.zipCode },
    longitude: { required: m.autocomplete },
    'chamber-count': { required: m.required },
    'year-of-reference': { required: m.required },
    'monthly-rent-amount': { required: m.required },
    email: { required: m.required, courriel: m.email },
    'confirm-email': { required: m.required },
  };

  formValidation('#form-step-1', '#form-step-1--submit, #form-step-1--next', rules, messages);

  addressAutocomplete();
}

export function formBumperFr() {
  const FORM = document.querySelector('#form-bumper-fr');

  if (typeof FORM === 'undefined' || FORM === null) { return; }

  const m = getMessages();

  const rules = {
    region: { required: true },
  };
  const messages = {
    region: { required: m.required },
  };

  formValidation('#form-bumper-fr', '#form-bumper-fr--submit', rules, messages);
}

export function formBumperEn() {
  const FORM = document.querySelector('#form-bumper-en');

  if (typeof FORM === 'undefined' || FORM === null) { return; }

  const m = getMessages();

  const rules = {
    region: { required: true },
  };
  const messages = {
    region: { required: m.required },
  };

  formValidation('#form-bumper-en', '#form-bumper-en--submit', rules, messages);
}
