import Swiper from 'swiper/bundle';

export default function swiperFilters() {
  const name = 'filters';
  if (!document.querySelector(`[data-swiper="${name}"]`)) { return; }

  window.swiperFilter = new Swiper(`[data-swiper="${name}"]`, {
    slidesPerView: 'auto',
    threshold: 10,
    speed: 300,
    freeMode: true,
    mousewheel: {
      forceToAxis: true,
      sensitivity: 0.5,
    },
    navigation: {
      prevEl: `[data-swiper-prev="${name}"]`,
      nextEl: `[data-swiper-next="${name}"]`,
      disabledClass: 'disabled',
    },
  });
}
